<template>
    <div id="wrapper" class="bg-white pt-4">
        <div class="container-fluid">
            <div class="row">
                <div class="col-xl-8 offset-xl-2 col-lg-8 col-md-8 offset-lg-2 offset-md-2">
                    <div class="d-flex">
                        <div class="text-left p-4 pb-2">
                            <h3 class="text-uppercase text-dark fw-bold mb-1 mt-2" style="letter-spacing: 1px">One Time Password</h3>
                            <p class="text-center mb-2">Please check your email for your One-time Password.</p>                          
                        </div>
                    </div>                    

                    <status v-model:status_data=status_data></status>

                    <form id="form" action="#" @submit.prevent="handleSubmit()">
                        <div class="card-body border-bottom-0 p-4 pb-2">
                            <input type="number" class="form-control me-2 bg-secondary text-center" id="otp" placeholder="Enter your verification code here." v-model="user_code">

                            <div class="row mt-2 text-end">
                                <span>
                                    Didn't receive an OTP? <span class="text-orange fw-bold" @click="sendOTP()">Resend OTP</span>
                                </span>
                            </div>

                            <div class="row" id="login">
                                <div class="col">
                                    <button class="btn bg-orange text-white btn-sm mt-4 w-100 text-uppercase fw-bold pt-2 pb-2" type="submit">
                                        <span style="letter-spacing: 2px">Submit</span> <font-awesome-icon class="icon ms-1 me-1" :icon="['fa', 'angle-right']" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                    
                </div>  
            </div>
        </div>

        <div class="container-fluid">
            <div class="row">
                <div class="container text-center">
                    <p class="ms-4">Don't have an account? <router-link :to="'/register'" class="text-orange text-decoration-none">Create One</router-link></p>
                </div>
            </div>
            
        </div>
    </div>
</template>

<style scoped lang="scss">
    @import '../../assets/scss/base/_base.scss';
    @import '../../assets/scss/base/_fonts.scss';
    @import '../../assets/scss/helpers/breakpoints.scss';

    .card {
        box-shadow: 0 4px 8px rgb(0 0 0 / 3%)
    }
    
</style>

<script>
    import Status from '../../components/Status';

    export default {
        name: 'Otp',
        components: {
            Status,
        },
        data() {
            return {
                data: {
                    email: '',
                    password: '',
                },
                code: null, 
                user_code: null,
                status_data: {
                    "success": false,
                    "success_message": 'You\'re in! Login is Successful!',
                    "error": false,
                    "error_message": 'There are some input errors.',
                },
                loading: false,
                otp_no: {},
            }
        },
        mounted() {
            this.getOTP()
        },        
        methods: {
            getOTP() {
                if( !JSON.parse(localStorage.getItem('user'))['email']) {
                    this.$router.push({name: 'Login'});
                }
                
                this.axios.get('api/get-otp?email=' + JSON.parse(localStorage.getItem('user'))['email']).then(response => {
                    this.code = response.data.data.code
                    this.created_at = response.data.data.created_at
                }).catch(errors => {
                    this.status_data.loading = false
                    this.status_data.success = false
                    this.status_data.error = true
                    this.status_data.error_message = errors.message
                })
            },            
            moveInput(event, nextFieldID) {
                if(event.value.length >= event.maxLength && nextFieldID != 'submit'){
                    document.getElementById(nextFieldID).focus();
                } else if(event.value.length >= event.maxLength && nextFieldID == 'submit'){
                    this.checkOTP()
                }
            },            
            handleSubmit() {
                if(this.user_code == this.code) {    
                    this.status_data.loading = false
                    this.status_data.error = false  
                    this.status_data.success = true
                    this.status_data.success_message = 'Success!'

                    // set token details
                    this.axios.post('api/sanctum/token', {
                        email: JSON.parse(localStorage.getItem('user'))['email'],
                        password: JSON.parse(localStorage.getItem('user'))['password']
                    }).then((response) => {
                        // remove email stored in Storage
                        localStorage.removeItem('user')

                        // reset user token details
                        localStorage.setItem('user', JSON.stringify({
                            'token': response.data.data.token, 
                            'expiry': response.data.data.expiry
                        }))   

                        if( !response.data.data.user.driver) {
                            // redirect to home if normal user
                            setTimeout(() => {
                                this.$router.push({name: 'Home'});
                            }, 2000); 
                        } else if(response.data.data.user.driver
                            && !response.data.data.user.driver_verified) {
                            // redirect to driver home if driver
                            setTimeout(() => {
                                this.$router.push({name: 'NotVerified'});
                            }, 2000); 
                        } else if(response.data.data.user.driver
                            && response.data.data.user.driver_verified) {
                            // redirect to driver home if driver
                            setTimeout(() => {
                                this.$router.push({name: 'DriverHome'});
                            }, 2000); 
                        }

                        // if doctor
                        if( !response.data.data.user.doctor) {
                            // redirect to home if normal user
                            setTimeout(() => {
                                this.$router.push({name: 'Home'});
                            }, 2000); 
                        } else if(response.data.data.user.doctor
                            && !response.data.data.user.doctor_verified) {
                            // redirect to doctor home if doctor
                            setTimeout(() => {
                                this.$router.push({name: 'NotVerified'});
                            }, 2000); 
                        } else if(response.data.data.user.doctor
                            && response.data.data.user.doctor_verified) {
                            // redirect to doctor home if doctor
                            setTimeout(() => {
                                this.$router.push({name: 'DoctorRequests'});
                            }, 2000); 
                        }

                    }).catch(errors => {
                        this.status_data.loading = false
                        this.status_data.success = false
                        this.status_data.error = true
                        this.status_data.error_message = errors.message
                    })
                } else {
                    this.status_data.loading = false
                    this.status_data.success = false
                    this.status_data.error = true

                    this.status_data.error_message = 'OTP Password mismatch.'
                }    
            },
            seePassword() {
                if(document.querySelector('#password').type == 'password') {
                    document.querySelector('#password').type = 'text'
                } else {
                    document.querySelector('#password').type = 'password'
                }
            }
        }
    }
</script>